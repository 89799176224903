@import url("https://fonts.googleapis.com/css?family=Sawarabi+Mincho");

* {
  font-family: "Sawarabi Mincho", sans-serif;
  scroll-behavior: smooth;
}

/* HTML */
html {
  background: url("img/bg_hex.png") center center;
  animation: bg-scroll-anim 40s linear infinite;
  background-size: 30%;
  height: 100%;
  overflow: hidden;
}

/* BODY */
body {
  background: transparent;
}

/* The document root where react injects itself into the mainframe of the central computer and starts converting the magi. */
#root {
  background: transparent;
}

/* The buttons used for selecting an option when taking a test */
.sentakushi-btn {
  background-image: url("img/btn_blank.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80% 100%;
  color: rgb(250, 250, 250);
  height: 100%;
  width: 100%;
  margin: 10px 0;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  padding-left: 16%;
  padding-right: 16%;
  padding-top: 3%;
  padding-bottom: 3%;
}

.option-letter {
  font-size: 1.6em;
  line-height: 1.2em;
  text-align: left;
  height: 100%;
  vertical-align: middle;
}

.option-text {
  vertical-align: middle;
  font-size: 1.1em;
  line-height: 1.2em;
  min-height: 1.3em;
  width: 100%;
  text-align: left;
  white-space: pre-wrap;
}

.sentakushi-btn p {
  display: table-cell;
  position: relative;
  top: 0;
  bottom: 0;
  height: 100%;
  vertical-align: middle;
  margin: 0;
}

.sentakushi-btn table tbody tr td:first-child {
  width: 14%;
}
.sentakushi-btn table {
  width: 100%;
}

.qr-output-container-outer {
  background-image: url("img/qr-box.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: fixed;
  overflow: hidden;
  bottom: 3vh;
  right: 1vh;
  width: 16vh;
  height: 3.5vh;
  font-size: 1.5vh;
  padding-top: 0.6vh;
}

.qr-output-container-inner {
  margin: 0 2.2vh;
  overflow: hidden;
}

/* The little box for displaying QR code data for debugging. */
.qr-output {
  display: inline-block;
  white-space: nowrap;
  animation: scrolling-text-anim 5s infinite linear;
  padding-left: 100%; /*Initial offset*/
  z-index: 1000;
}

/* Used for the tesuto kaishi button */
.btn-available.btn-show-result {
  color: lightgreen;
  animation: test-available-anim 0.75s ease-in-out infinite alternate;
}

/* The main container element */
.main-container {
  position: absolute;
  overflow-y: hidden;
  overflow-x: hidden;
  /* top: 0; */
  /* top: 30px; */
  /* padding-bottom: 50px; */
  max-height: 100%;
}

/* Eva styled general use buttons */
.eva-btn {
  border-radius: 0;
  background-color: none;
  background-image: url("img/btn_blank.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  color: rgb(250, 250, 250);
  margin: 5px 15px 15px 15px;
  padding: 10px;
  font-weight: 600;
  font-size: 0.9em;
  white-space: nowrap;
}

/* Container for the logo */
.logo-container {
  vertical-align: top;
  overflow: hidden;
}

/* The logo image */
.logo-container img {
  position: absolute;
  top: 10%;
  max-width: 90%;
  z-index: 50;
}

/* Main container for the question text and the options. */
.question-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 700px;
  max-height: 1024px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 0px;
  box-sizing: border-box;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0;
}

/* Title for the option analysis */
.title {
  position: relative;
  color: rgb(0, 0, 0);
  padding: 10px 20px 0 20px;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
}

/* Title for the option analysis */
.option-desc-title {
  display: block !important;
  position: relative;
  color: rgb(0, 0, 0);
  padding-bottom: 5% !important;
  font-size: 1.6em;
  font-weight: bold;
  text-align: center;
  white-space: pre-wrap;
}

/* Title for the option analysis */
.option-desc-title-first {
  font-size: 1.0em !important;
  font-weight: normal !important;
  padding-bottom: 0% !important;
}

/* Conatiner for question text */
.question-text-box {
  background-image: url("img/question_background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  font-size: 1.2em;
  font-weight: bold;
  margin: auto;
  text-align: center;
  margin-bottom: 5px;
  min-height: 40%;
}

/* Align the question text in the middle vertically */
.question-text-box div {
  margin: 0;
  padding: 20% 10%;
  white-space: pre-wrap;
  text-align: justify;
}

/* Modifications for the popup window */
body.modal-open .modal {
  display: flex !important;
  height: 100%;
  padding: 20px;
}

/* Modifications for the popup window */
body.modal-open .modal .modal-dialog {
  margin: auto;
}

/* Modifications for the popup window */
.modal-content {
  background-image: url("img/modal_bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

/* The header element of the popup modal */
.modal-header {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 3000px;
  padding: 2rem;
}

/* The X in the top right corner of a popup modal */
.modal-header .close {
  font-size: 2rem;
}

/* The header element of the popup modal */
.modal-body {
  white-space: pre-wrap;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 3000px;
  padding: 2rem;
}

/* Modifications for the popup window */
.modal-footer {
  justify-content: center !important;
  padding: 0 25%;
}

/* Modifications for the popup window */
.modal-content {
  justify-content: center !important;
  padding: 0;
  width: 100%;
  margin: 0 auto;
}

/* Container for just the question text if preceeded by an image */
.question-image + .question-text {
  padding: 0% 10% 10% 10%;
}

/* Container for just the question image */
.question-image {
  padding: 10% 10% 5% 10% !important;
  text-align: center !important;
}

/* The question image */
.question-image img {
  max-width: 100%;
}

/* Container for the options in the questionbox.js component */
.options-container {
  margin: 20px auto 10px auto;
}

/* Answer analysis title in questionbox component */
.answer-analysis-title {
  position: relative;
  max-width: 100%;
  z-index: 1;
}

/* Answer explanation box in questionbox component */
.option-desc-box {
  padding-bottom: 60px !important;
}

/* Answer explanation box in questionbox component */
.option-desc-box > .question-text-box {
  min-height: 42%; /* this is the answer */
  white-space: pre-wrap;
  background-image: url("img/answer_analysis_bg.png");
  animation: site-load-anim 0.6s;
}

/* Box containing buttons in answer explanation box in questionbox component */
.option-desc-box > .next-btn-box {
  align-self: end;
}

/* Answer explanation box in questionbox component */
.option-desc-text {
  display: block;
  padding: 15% 10% 5% 10% !important;
  text-align: justify;
  font-weight: bold;
  line-height: 1.3em;
}
.option-desc-text:first-of-type {
  padding: 15% 10% 5% 10% !important;
  text-align: left;
}
.option-desc-text:last-of-type {
  padding: 15% 10% 20% 10% !important;
  text-align: left;
}

    .option-desc-text > div:first-child {
        padding: 2%;
        text-align: center;
        white-space: nowrap;
        margin-top: 0px;
        margin-right: -10%;
        margin-bottom: 5%;
        margin-left: -10%;
    }

.option-desc-text > div {
  padding: 2%;
  text-align: justify;
}

.option-desc-text.selected > div:first-child {
  font-size: 1em;
}

.option-desc-text.selected > div:first-child:before {
  content: ">";
  position: relative;
  vertical-align: top;
  font-size: 0.8em;
  animation: point-right-anim 2.5s infinite ease-in-out;
}
.option-desc-text.selected > div:first-child:after {
  content: "<";
  position: relative;
  vertical-align: top;
  font-size: 0.8em;
  animation: point-left-anim 2.5s infinite ease-in-out;
}

/* Button box in questionbox component */
.next-btn-box {
  display: flex;
  position: relative;
  bottom: 0;
  justify-content: center;
  width: 100%;
  padding: 5% 10% !important;
}

/* The button for starting the tests */
.btn-start-test {
  background-image: url("img/btn_start_test_jp.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  width: 80%;
  height: 100%;
  animation: test-available-anim 1.85s ease-in-out infinite alternate;
}

/* English translation class for the button for starting the tests  */
.btn-start-test-en {
  background-image: url("img/btn_start_test_en.png");
}

/* English translation class for the button for starting the tests  */
.btn-start-test-zh {
  background-image: url("img/btn_start_test_zh.png");
}

/* The button for starting the tests */
.btn-start-test:active {
  position: relative !important;
  top: 0px !important;
  left: 0px !important;
  width: 78% !important;
}

/* Container for the button for starting the tests */
.start-button-box {
  position: relative;
  top: 5% !important;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0 auto !important;
  width: 100% !important;
  height: auto;
  overflow: visible !important;
  display: flex;
  height: 100% !important;
}

/* Wallpaper preview container */
.wallpaper-preview {
  flex-direction: column;
  display: flex;
  height: 85%;
  align-items: center;
  justify-content: center;
  background-image: url("img/question_background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding: 10%;
  margin-top: 5%;
}

/* Wallpaper preview img element */
.wallpaper-preview img {
  display: block;
  width: auto;
  max-height: 100%;
  max-width: 100%;
}

/* Dropdown for wallpaper resolution select */
.wp-resolution-select {
  max-width: 100%;
  min-width: 200px;
  padding: 5px;
  margin: 5px;
  border: 5px black;
  border-style: inset;
}

/* This is used all over the place */
.vertical-middle-box {
  /* margin-top: 20px; */
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  /* padding: 20px; */
  height: 70%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

/* Used on the frontpage */
.site-loader {
  position: absolute;
  height: 100%;
}

/* Used to animate elements upon loading */
.site-loader-animation {
  animation: site-load-anim 0.7s ease;
}

/* Used to page upon loading */
.page-loader {
  position: relative;
  height: 100%;
  top: 0;
  animation: page-load-anim 0.4s linear forwards;
}

/* Used when loading wallpapers */
.wallpaper-loader {
  animation: page-load-anim 0.7s linear forwards;
}

/* Used when options are selected */
.button-press-animation {
  animation: enlarge-anim 0.9s ease-in;
}

/* Used to fade out question after selecting an option */
.fade-out {
  animation: question-deload-anim 0.5s ease-in;
  animation-delay: 0.4s;
}

/* Used to fade in option description after selecting an option */
.option-description-fade-in {
  animation: option-desc-load-anim 1s linear;
}

/* Used to animate loading a question */
.question-loader-animation {
  animation: page-load-anim 0.7s linear forwards;
}

/* Modified vertical middle box for the question component */
.question-box.vertical-middle-box {
  /* margin-top: 20px; */
  padding: 0;
  padding-left: 4%;
  padding-right: 4%;
  position: absolute;
  top: 15%;
  height: 75%;
  left: 50%;
  transform: translateX(-50%);
  /* padding: 20px;
  padding-top: 0;
  height: 100%;
  width: 100%; */
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Used for the bottom part of the site on some pages */
.vertical-bottom-box {
  display: flex;
  width: 100%;
  height: 25%;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
}

/* The box that is displayed when all tests are completed */
.tests-completed-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Top border overlay for all pages */
.top-border {
  background-image: url("img/overlay_top.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100%;
  display: flex;
  width: 100%;
  height: 25%;
  padding-top: 5px;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 1;
  pointer-events: none;
  z-index: 99;
}

/* Top border overlay image (the logo) for all pages */
.top-border img {
  position: absolute;
  top: 16px;
  margin-bottom: 50px;
  z-index: 100;
  height: auto;
}

/*�B�����O�C���{�^��*/
.hidden-login-btn {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 500;
  width: 60px;
  height: 60px;
  pointer-events: all;
}

/* Bottom border overlay for all pages */
.bottom-border {
  background-image: url("img/overlay_bottom.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  display: flex;
  width: 100%;
  height: 10%;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  opacity: 1;
  pointer-events: none;
}

/* Default class for qr camera activation button */
.btn-activate-qr {
  background-image: url("img/btn_camera_jp.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

/* English translation class for qr camera activation button */
.btn-activate-qr-en {
  background-image: url("img/btn_camera_en.png") !important;
}
/* Swedish translation class for qr camera activation button */
.btn-activate-qr-sv {
  background-image: url("img/btn_camera_en.png") !important;
}
/* Chinese translation class for qr camera activation button */
.btn-activate-qr-zh {
  background-image: url("img/btn_camera_zh.png") !important;
}

/* Base class for buttons with that already have text on them */
.btn-skinned {
  will-change: filter;
  padding: 0;
  margin-bottom: 20px;
  width: 75%;
  height: auto;
  min-height: 100px;
  outline: red !important;
  box-shadow: red !important;
  outline-color: red !important;
  -webkit-tap-highlight-color: rgba(255, 0, 0, 0) !important;
}

/* For when button elements are focused */
button:focus {
  outline-color: red !important;
  box-shadow: none !important;
  outline-color: green !important;
  -webkit-tap-highlight-color: rgba(255, 0, 0, 0) !important;
  filter: invert(1%) drop-shadow(0 0 3px rgba(170, 74, 51, 255));
}

/* For when button elements are pressed */
button:active {
  position: relative;
  top: 2px;
  left: 0px;
  color: rgba(170, 74, 51, 255);
}

/* For when button elements are hovered */
button:hover {
  color: rgba(210, 210, 210, 255) !important;
}

/* Default class for camera container (jp) */
.camera-container {
  background-image: url("img/qr_camera_bg_jp.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  height: 100%;
  padding: 20% 5% 15% 5%;
}

/* English class for camera container */
.camera-container-en {
  background-image: url("img/qr_camera_bg_en.png") !important;
}
/* Swedish class for camera container */
.camera-container-sv {
  background-image: url("img/qr_camera_bg_en.png") !important;
}
/* Chinese class for camera container */
.camera-container-zh {
  background-image: url("img/qr_camera_bg_zh.png") !important;
}

/* Blank background for when camera is active */
.camera-container.camera-active {
  background-image: none !important;
}

/* Animation when revealing camera */
.camera-container.camera-active .camera-inner {
  animation: camera-reveal-anim 1s linear forwards;
}

/* Part of the camera element */
.camera-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* When loading wallpaper page */
.uploading-upper,
.uploading-lower {
  width: 100%;
}

/* For tests completed phase */
.tests-completed {
  width: 100%;
  animation: flicker 6s infinite linear;
}

/* Default (japanese) class for next question button */
.btn-next {
  background-image: url("img/btn_next_jp.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  padding: 10%;
  margin-bottom: 5%;
}
/* English class for next question button */
.btn-next-en {
  background-image: url("img/btn_next_en.png") !important;
}
/* Swedish class for next question button */
.btn-next-sv {
  background-image: url("img/btn_next_en.png") !important;
}
/* Chinese class for next question button */
.btn-next-zh {
  background-image: url("img/btn_next_zh.png") !important;
}

/* Default (japanese) class for back button */
.btn-back {
  background-image: url("img/btn_back_jp.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  padding: 20%;
}
/* English class for back button */
.btn-back-en {
  background-image: url("img/btn_back_en.png") !important;
}
/* Swedish class for back button */
.btn-back-sv {
  background-image: url("img/btn_back_en.png") !important;
}
/* Chinese class for back button */
.btn-back-zh {
  background-image: url("img/btn_back_zh.png") !important;
}

/* Default (japanese) class for save button */
.btn-save {
  background-image: url("img/btn_save_jp.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  padding: 20%;
}
/* English class for save button */
.btn-save-en {
  background-image: url("img/btn_save_en.png") !important;
}
/* Swedish class for save button */
.btn-save-sv {
  background-image: url("img/btn_save_en.png") !important;
}
/* Chinese class for save button */
.btn-save-zh {
  background-image: url("img/btn_save_zh.png") !important;
}

/* Default (japanese) class for show result button */
.btn-show-result {
  background-image: url("img/btn_result_jp.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  margin: 5% auto 0 auto;
}
/* English class for show result button */
.btn-show-result-en {
  background-image: url("img/btn_result_en.png") !important;
}
/* Swedish class for show result button */
.btn-show-result-sv {
  background-image: url("img/btn_result_en.png") !important;
}
/* Chinese class for show result button */
.btn-show-result-zh {
  background-image: url("img/btn_result_zh.png") !important;
}

/**** FOR THE ANIMATED PROGRESS BAR IN THE WALLPAPER COMPONENT *****/
.meter {
  height: 40px; /* Can be anything */
  position: relative;
  margin: 20px 0 20px 0; /* Just for demo spacing */
  background: transparent;
  border-radius: 0;
  padding: 5px;
  border: solid 1px #ffb603;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}
.meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: rgb(43, 194, 83);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, rgb(43, 194, 83)),
    color-stop(1, rgb(84, 240, 84))
  );
  background-image: -moz-linear-gradient(
    center bottom,
    rgb(43, 194, 83) 37%,
    rgb(84, 240, 84) 69%
  );
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
  animation: fill 5s linear forwards;
}
.meter > span:after,
.animate > span > span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: -webkit-gradient(
    linear,
    0 0,
    100% 100%,
    color-stop(0.25, rgba(255, 255, 255, 0.2)),
    color-stop(0.25, transparent),
    color-stop(0.5, transparent),
    color-stop(0.5, rgba(255, 255, 255, 0.2)),
    color-stop(0.75, rgba(255, 255, 255, 0.2)),
    color-stop(0.75, transparent),
    to(transparent)
  );
  background-image: -moz-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  animation: move2 0.8s linear infinite;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  overflow: hidden;
}

.animate > span:after {
  display: none;
}

@keyframes move2 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

@keyframes fill {
  0% {
    width: 0;
  }
  20% {
    width: 20%;
  }
  40% {
    width: 22%;
  }
  65% {
    width: 50%;
  }
  75% {
    width: 55%;
  }
  80% {
    width: 80%;
  }
  90% {
    width: 80%;
  }
  100% {
    width: 100%;
  }
}

.orange > span {
  background-color: #f1a165;
  background-image: -moz-linear-gradient(top, #f1a165, #ffb603);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #f1a165),
    color-stop(1, #ffb603)
  );
  background-image: -webkit-linear-gradient(#f1a165, #f36d0a);
}

.red > span {
  background-color: #b22e63;
  background-image: -moz-linear-gradient(top, #f0a3a3, #b22e63);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #000000),
    color-stop(1, #b22e63)
  );
  background-image: -webkit-linear-gradient(#b22e63, #b22e63);
}

.nostripes > span > span,
.nostripes > span:after {
  animation: none;
  background-image: none;
}

.wallpaper-result-loader {
  height: 100%;
}

.progressbar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 20%;
}

.touch_screen_str {
    position: relative;
    top: 85%;
}

/**** END OF PROGRESS BAR ****/

/******** FOR EMULATING A SMARTPHONE IF SCREEN SIZE IS BIGLY YUGE, LIKE MY HANDS *****/

@media screen and (min-width: 576px) {
  /* Hide the fullscreen background */
  html {
    background: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
    overflow: hidden;
  }

  body {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .btn-start-test {
    margin-top: 70%;
    z-index: 100;
  }

  .main-container {
    background: url(img/bg_hex.png) center center;
    background-size: 30%;
    width: 360px;
    height: 740px;
    position: absolute;
    top: 25%;
    left: 0;
    animation: bg-scroll-anim 40s linear infinite;
    overflow: hidden;
  }

  .progressbar-container {
    padding-top: 20%;
  }

  /* The device with borders */
  .smartphone {
    position: relative;
    width: 360px;
    height: 740px;
    margin: auto;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .page-loader {
    position: relative;
    height: 100%;
    top: 0;
    animation: page-load-anim 0.3s linear forwards;
  }

  /* The horizontal line on the top of the device */
  .smartphone:before {
    content: "";
    background-size: 30%;
    width: 360px;
    height: 740px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  /* The screen (or content) of the device */
  .smartphone .content {
    width: 360px;
    height: 740px;
    background: white;
  }

  .modal-dialog {
    width: 360px;
    margin: 0 auto;
    padding: 40px;
  }

  .modal-content {
    justify-content: center !important;
    padding: 0;
    width: 100%;
    margin: 0 auto;
  }

  .vertical-middle-box {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 0;
    height: 60%;
    width: 100%;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .bottom-border {
    width: 360px;
    left: 50%;
    transform: translateX(-50%);
  }

  .top-border {
    width: 360px;
    left: 50%;
    transform: translateX(-50%);
  }

  .start-button-box {
    height: 65% !important;
  }

  .qr-output-container-outer {
    background-image: url("img/qr-box.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: fixed;
    overflow: hidden;
    bottom: 2vh;
    right: 2vh;
    width: 9vh;
    height: 2.5vh;
    font-size: 1.2vh;
    padding-top: 0.35vh;
  }

  .qr-output-container-inner {
    margin: 0 1.1vh;
    overflow: hidden;
  }
}

/******** END SMARTPHONE EMULATION *****/

/******** MEDIA QUERIES FOR ACTUAL SMARTPHONES *****/

@media screen and (max-height: 800px) {
  .question-box.vertical-middle-box {
    top: 15%;
    height: 75%;
  }
  .vertical-middle-box {
    height: 70%;
  }
}

@media screen and (max-height: 700px) {
  .question-box.vertical-middle-box {
    top: 20%;
    height: 70%;
  }
  .camera-container {
    background-size: 85%;
  }
  .tests-completed-box {
    padding-top: 40%;
    padding-bottom: 40%;
  }
  .tests-completed {
    width: 80%;
  }
  .btn-show-result {
    margin-top: 0;
    width: 60%;
  }
}
@media screen and (max-height: 600px) {
  .question-box.vertical-middle-box {
    top: 20%;
    height: 70%;
  }
  .camera-container {
    background-size: 75%;
  }
  .tests-completed {
    width: 80%;
  }
  .btn-show-result {
    margin-top: 0;
    width: 60%;
  }
}

/******** END MEDIA QUERIES FOR ACTUAL SMARTPHONES *****/

/******* KEYFRAMES FOR ANIMATIONS *******/

/* Animation for indicating that a test is available */
@keyframes test-available-anim {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.025);
  }
}

/* @keyframes test-available-anim {
  from {
    opacity: 1;
    filter: invert(0%) hue-rotate(10deg)
      drop-shadow(0 0 20px rgba(170, 74, 51, 255));
  }
  to {
    filter: invert(15%);
  }
} */

/* Animation for loading site and certain elements
Basically just flickers and upscales from zero. */
@keyframes site-load-anim {
  0% {
    transform: scaleY(0);
    opacity: 0;
  }
  30% {
    opacity: 0.3;
  }
  31% {
    opacity: 1;
  }
  34% {
    opacity: 0;
  }
  36% {
    opacity: 1;
  }
  38% {
    opacity: 0.38;
  }
  60% {
    opacity: 0.6;
  }
  65% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
  77% {
    opacity: 1;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

/* For fading out question info after selecting an answer */
@keyframes question-deload-anim {
  0% {
    opacity: 1;
    transform: translateX(-50%) scaleY(1);
  }
  30% {
    opacity: 0.8;
  }
  31% {
    opacity: 1;
  }
  34% {
    opacity: 0;
  }
  77% {
    opacity: 1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    transform: translateX(-50%) scaleY(0);
    opacity: 0;
  }
}

/* For fading in option explanation after selecting an answer */
@keyframes option-desc-load-anim {
  0% {
    opacity: 0;
    transform: translateX(-50%) scaleY(0);
  }
  30% {
    opacity: 0.3;
  }
  31% {
    opacity: 1;
    transform: translateX(-50%) scaleY(1);
  }
  34% {
    opacity: 0;
  }
  36% {
    opacity: 1;
  }
  38% {
    opacity: 0.38;
  }
  60% {
    opacity: 0.6;
  }
  65% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
  77% {
    opacity: 1;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    transform: translateX(-50%);
    opacity: 1;
  }
}

/* For transitioning between pages.
Basically just flicker. */
@keyframes page-load-anim {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0.3;
  }
  31% {
    opacity: 1;
  }
  34% {
    opacity: 0;
  }
  36% {
    opacity: 1;
  }
  38% {
    opacity: 0.38;
  }
  60% {
    opacity: 0.6;
  }
  65% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
  77% {
    opacity: 1;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

/* For transitioning between pages.
Basically just flicker. */
@keyframes flicker {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
    transform: scale(1);
  }
  92% {
    transform: skewX(0);
    opacity: 0;
  }
  93% {
    transform: scale(1.01) skewX(-2deg);
    opacity: 1;
  }
  94% {
    transform: skewX(2deg);
    opacity: 0.38;
  }
  95% {
    opacity: 0.6;
    transform: scale(1);
  }
  96% {
    opacity: 1;
  }
  98% {
    transform: scale(1.03) skewX(-1deg);
    opacity: 0;
  }
  99% {
    transform: scale(1) skewX(0);
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

/* The wacky animation when revealing the camera */
@keyframes camera-reveal-anim {
  0% {
    transform-origin: center;
    transform: translateY(0) scaleY(0);
    opacity: 0;
  }
  10% {
    opacity: 0.3;
  }
  11% {
    opacity: 1;
  }
  14% {
    opacity: 0;
  }
  16% {
    transform: translateY(0) scaleY(1);
    opacity: 1;
  }
  18% {
    transform: translateY(0) scaleY(0.38);
    opacity: 0.38;
  }
  30% {
    transform: translateY(0) scaleY(0.6);
    opacity: 0.6;
  }
  35% {
    opacity: 1;
  }
  40% {
    transform: translateY(0) scaleY(0.3) skewY(-2deg) skewX(-2deg);
    opacity: 0;
  }
  45% {
    transform: translateY(0) skewY(-2deg);
    opacity: 1;
  }
  46% {
    transform: translateY(0) scaleY(0.6);
    opacity: 0;
  }
  47% {
    transform: translateY(-10%) skew(2deg);
    opacity: 1;
  }
  50% {
    transform: translateY(10%) skew(-2deg);
    opacity: 0.8;
  }
  55% {
    transform: translateY(-5%) scaleY(0.5) skew(20deg);
    opacity: 0.8;
  }
  60% {
    transform: translateY(0%) scaleY(0.2) skew(-15deg);
    opacity: 0.8;
  }
  75% {
    transform: translateY(5%) skew(2deg);
    opacity: 0.8;
  }
  80% {
    transform: translateY(0%) skew(-2deg);
    opacity: 0.8;
  }
  85% {
    transform: translateY(2%) scaleY(0.1) skew(2deg);
    opacity: 0.2;
  }
  95% {
    transform: translateY(0) scaleY(1) skew(0deg);
    opacity: 1;
  }
  97% {
    opacity: 0.2;
  }
  98% {
    opacity: 1;
  }
  99% {
    opacity: 0.2;
  }
  100% {
    transform: translateY(0) scaleY(1);
    opacity: 1;
    visibility: visible;
  }
}

/* For the scrolling background */
@keyframes bg-scroll-anim {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 855px;
  }
}

/* Used when selecting an option.
Makes the button bigger and transparent. */
@keyframes enlarge-anim {
  0% {
    transform: scaleY(1);
  }
  20% {
    transform: scale(0.8);
  }
  100% {
    transform: scaleY(2);
    opacity: 0;
  }
}

/* The scrolling text to the bottom right (the guest id) */
@keyframes scrolling-text-anim {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

/* The bouncy arrows for indicating the desription for the users selected option */
@keyframes point-left-anim {
  0% {
    left: 0;
  }
  50% {
    left: 2%;
  }
  100% {
    left: 0;
  }
} /* The bouncy arrows for indicating the desription for the users selected option */
@keyframes point-right-anim {
  0% {
    right: 0;
  }
  50% {
    right: 2%;
  }
  100% {
    right: 0;
  }
}

/******* END KEYFRAMES FOR ANIMATIONS *******/
